
import auth from '../services/auth'
import validate from '../services/validator'
import router from './../router'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { defineComponent, reactive, ref, UnwrapRef } from 'vue'
import { notification } from 'ant-design-vue'

interface FormState {
  email: string;
  password: string;
  remember: boolean;
}

interface ForgotFormState {
  email: string;
}
export default defineComponent({
  props: [],
  setup () {
    const title = ref<string>(process.env.VUE_APP_PROJECT_NAME)
    const slogan = ref<string>(process.env.VUE_APP_SLOGAN)
    const loading = ref<boolean>(false)
    const formState: UnwrapRef<FormState> = reactive({
      email: '',
      password: '',
      remember: false
    })
    const forgotFormState: UnwrapRef<ForgotFormState> = reactive({
      email: ''
    })
    const visible = ref<boolean>(false)
    const confirmLoading = ref<boolean>(false)
    const rules = validate.loginValidation
    const forgotRules = validate.forgotPasswordValidation
    const formRef = ref()
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const login = async (values: FormState) => {
      loading.value = true
      try {
        const res = await auth.login(values)
        if (res.data.auth) {
          localStorage.accessToken = res.data.token
          localStorage.profile = JSON.stringify(res.data.profile)
          localStorage.clientLogo = res.data.clientLogo
          localStorage.clientId = res.data.clientId
          if (res.data.profile.profile_type.slug === 'client' && res.data.profile.status === 'unpaid') {
            router.push('/client/subscription')
          } else if (res.data.profile.profile_type.slug === 'candidate') {
            router.push('/candidate/course')
          } else {
            if (res.data.profile.profile_type.slug === 'client' || res.data.profile.profile_type.slug === 'system_users') {
              if (res.data.subscription) {
                localStorage.subscription = JSON.stringify(res.data.subscription[0])
              }
              router.push('/client/dashboard')
            } else {
              router.push('/dashboard')
            }
          }
        } else {
          await notify('Error', res.data, 'error')
        }
        loading.value = false
      } catch (error) {
        loading.value = false
        localStorage.removeItem('accessToken')
        await notify('Error', error.data, 'error')
      }
    }
    const handleFinishFailed = (errors: ValidateErrorEntity<FormState>) => {
      console.log('errors', errors)
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }
    const showForgotPopup = () => {
      visible.value = true
    }
    const sendPassword = async (values: ForgotFormState) => {
      try {
        const res = await auth.forgotPassword({ email: values.email })
        if (typeof res.data === 'boolean') {
          await notify('Success', 'New password has been send to your registered email.', 'success')
          resetForm()
        } else {
          await notify('Error', res.data, 'error')
          return
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
      visible.value = false
      confirmLoading.value = false
    }
    const handleCancel = () => {
      visible.value = false
      confirmLoading.value = false
    }
    const signup = async () => {
      router.push('/signup')
    }
    return {
      formState,
      formRef,
      handleFinishFailed,
      login,
      resetForm,
      loading,
      rules,
      notify,
      showForgotPopup,
      sendPassword,
      forgotFormState,
      visible,
      confirmLoading,
      handleCancel,
      signup,
      forgotRules,
      title,
      slogan
    }
  }
})
